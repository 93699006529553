import React from 'react';

const FaqQuestion = () => {
    return (
        <>
            <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="sc-heading text-center sc-mb-60">
                                <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                                <div className="description">
                                    Welcome to our Frequently Asked Questions page! Here, we've compiled a list of the most common questions we receive from our customers. If you don't see an answer to your question here, please feel free to reach out to us directly.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Why are restaurant digital services necessary?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Restaurant digital services are necessary for a variety of reasons, one of which is the cost-saving benefits they provide in terms of labour.<br />
                                             For example, self-service kiosks can reduce the excessive need for front-of-house staff and kitchen ticket <br />
                                             systems can improve efficiency in the back-of-house which improves order accuracy and reduce the chance of errors.<br />
                                             These digital services can help restaurants streamline their operations and ultimately improve their bottom line. <br />
                                             Additionally, Sales dashboard can help restaurant owners make data-driven decisions to improve menu offerings and optimize sales.<br />
                                             Overall, these digital services can help staff serve customers in a more efficient and effective manner<br />
                                             </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            How do QR codes help restaurants with customer engagement and ordering?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>QR codes make it easy for customers to access digital menus, promotions, and order directly from their smartphones. 
                                             By using QR codes, restaurants can provide a contactless and streamlined experience to customers while reducing wait times and increasing efficiency.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                            How can self-ordering kiosks benefit restaurants and improve the customer experience?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Self-ordering kiosks enable customers to place their orders quickly and accurately, 
                                            without the need for human interaction. This can help reduce wait times and errors, while improving  
                                            the overall customer experience. Kiosks can also help restaurants increase revenue by upselling and  
                                            promoting add-ons or combo deals</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour">
                                            What are the advantages of online ordering for restaurants and customers?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Online ordering allows customers to conveniently browse menus, place orders, and pay online from  
                                            the comfort of their own homes. This can help restaurants increase sales and reach a wider audience,  
                                            while also reducing labor costs and increasing efficiency. Online ordering also provides customers with greater 
                                            flexibility and control over their orders, while enabling restaurants to collect valuable data and insights for  
                                            future marketing and operational decisions.</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive">
                                            How can restaurant digital services help save money for the restaurant?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Restaurant digital services such as online ordering and self-ordering kiosks can help reduce labour 
                                            costs by automating the ordering process. Additionally, by providing customers with digital menus and  
                                            promotions, restaurants can save money on printing costs and reduce waste. Digital services can also  
                                            help restaurants optimize their inventory and reduce food waste by providing real-time data and insights into customer demand.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;