import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';

import LatestBlog from '../../Shared/LatestBlog';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer'; 
import PrivacyPolicyTeam from './PrivacyPolicyTeam';

const PrivacyPolicy = () => {
    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Privacy Policy' />
            <PrivacyPolicyTeam />
            <LatestBlog />
            <Apps />
            <Footer />
        </>
    );
};

export default PrivacyPolicy;