import { config } from "../config";
import axios from 'axios';

//ContactUs 
export const ContactUs = async (data) => {

    return await axios.post(
        config.api_url + config.url_version + config.pathname + "/contact",
        data,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "api-key": `${config.api_key}`,
                "Content-Type": "text/plain"
            }
        }
    ).then(response => {
        return response.data;
    })
        .catch(error => {
            var response = {
                code: '0',
                message: error.message
            }
            return response;
            // return error.message;
        });
}

//SubscribeUser 
export const SubscribeUser = async (data) => {

    return await axios.post(
        config.api_url + config.url_version + config.pathname + "/subscribe",
        data,
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "api-key": `${config.api_key}`,
                "Content-Type": "text/plain"
            }
        }
    ).then(response => {
        return response.data;
    })
        .catch(error => {
            var response = {
                code: '0',
                message: error.message
            }
            return response;
            // return error.message;
        });
}

//SubscribeUser 
export const getWebsiteInfo = async () => {

    return await axios.get(
        config.api_url + config.url_version + config.pathname + "/get_WebsiteInfo",
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "api-key": `${config.api_key}`,
                "Content-Type": "text/plain"
            }
        }
    ).then(response => {
        console.log(response.data);
        return response.data;
    })
        .catch(error => {
            var response = {
                code: '0',
                message: error.message
            }
            return response;
            // return error.message;
        });
}