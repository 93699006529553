import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ContactUs } from '../../../services/Services';

const ContactInfo = () => {

    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
        phoneError: ''
    });

    const [captchaText, setCaptchaText] = useState('');
    const [userInput, setUserInput] = useState('');
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        initializeCaptcha(ctx);
    }, []);

    const handelChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'phone') {
            const isValidNumber = value.length >= 10 && /^\d+$/.test(value);
            if (isValidNumber) {
                setContactData({
                    ...contactData,
                    ['phone']: value,
                    ['phoneError']: ''
                })
            }
            else {
                setContactData({
                    ...contactData,
                    ['phoneError']: 'Enter atleast 10 digits of phone number'
                })
            }
        }
        else {
            setContactData({
                ...contactData,
                [name]: value
            })
        }

    }

    const generateRandomChar = (min, max) =>
        String.fromCharCode(Math.floor
            (Math.random() * (max - min + 1) + min));

    const generateCaptchaText = () => {
        let captcha = '';
        for (let i = 0; i < 3; i++) {
            captcha += generateRandomChar(65, 90);
            captcha += generateRandomChar(97, 122);
            captcha += generateRandomChar(48, 57);
        }
        return captcha.split('').sort(
            () => Math.random() - 0.5).join('');
    };

    const drawCaptchaOnCanvas = (ctx, captcha) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
        const letterSpace = 150 / captcha.length;
        for (let i = 0; i < captcha.length; i++) {
            const xInitialSpace = 25;
            ctx.font = '20px Roboto Mono';
            ctx.fillStyle = textColors[Math.floor(
                Math.random() * 2)];
            ctx.fillText(
                captcha[i],
                xInitialSpace + i * letterSpace,

                // Randomize Y position slightly 
                Math.floor(Math.random() * 16 + 25),
                100
            );
        }
    };

    const initializeCaptcha = (ctx) => {
        setUserInput('');
        const newCaptcha = generateCaptchaText();
        setCaptchaText(newCaptcha);
        drawCaptchaOnCanvas(ctx, newCaptcha);
    };

    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleCaptchaSubmit = (event) => {
        event.preventDefault();

        if (userInput === captchaText) {
            handleMessage(event);
            setUserInput('');
        } else {
            toast.error('Incorrect Captcha');
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            initializeCaptcha(ctx);
        }
    };

    const handleMessage = (event) => {
        event.preventDefault();
        event.target.reset();

        const params = {
            name: contactData['name'],
            email: contactData['email'],
            message: contactData['message'],
            phone: contactData['phone'],
        }
        console.log(params);
        ContactUs(params).then(response => {
            if (response.code === '1') {
                toast.success(response.message);
            }
            else {
                toast.error(response.message);
            }
        })
    };

    return (
        <>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">Get In Touch</span>
                                    <h2 className="heading-title sc-mb-45">Our Team Is Ready To Support You</h2>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-map-pin-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul>
                                            <li>71 Longton Street<span> Blackburn, BB1 1UF</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box">
                                    <div className="contact_icon">
                                        <i className="ri-mail-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href="mailto:admin@meal-rush.com">admin@meal-rush.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <i className="ri-phone-fill"></i>
                                    </div>
                                    <div className="contact-service-box">
                                        <ul className="contact-list">
                                            <li><a href="tel:(00) 44 7850 251 913">(00) 44 7850 251 913</a></li>
                                            <li><a href="tel:(00) 44 1254 801 824">(00) 44 1254 801 824</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-10">
                                    
                                    <form onSubmit={handleCaptchaSubmit}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <label className="sc-mb-10">Name*</label>
                                                <input
                                                    className="from-control"
                                                    type="text" id="name"
                                                    name="name"
                                                    required
                                                    autoComplete="off"
                                                    onChange={(e) => handelChange(e)}
                                                />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Email*</label>
                                                <input
                                                    className="from-control"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    required
                                                    autoComplete="off"
                                                    onChange={(e) => handelChange(e)}
                                                />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Phone*</label>
                                                <input
                                                    className="from-control"
                                                    type="number"
                                                    id="phone"
                                                    required
                                                    name="phone"
                                                    autoComplete="off"
                                                    onChange={(e) => handelChange(e)}
                                                />
                                                {
                                                    contactData.phoneError ?
                                                        <label className="sc-mb-2 text-red">{contactData.phoneError}</label>
                                                        : ''
                                                }
                                            </div>
                                            <div className="form-box">
                                                <label>Message*</label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    autoComplete="off"
                                                    required
                                                    onChange={(e) => handelChange(e)}
                                                >
                                                </textarea>
                                            </div>
                                            <div className='form-box'>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-8 col-8">
                                                        <div className="wrapper">
                                                            <canvas ref={canvasRef}
                                                                width="300"
                                                                height="70">
                                                            </canvas>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-4">
                                                        <button type='button' id="reload-button" onClick={
                                                            () => initializeCaptcha(
                                                                canvasRef.current.getContext('2d'))}>
                                                            <i class="ri-restart-fill"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    id="user-input"
                                                    name="phone"
                                                    placeholder="Enter the text in the image"
                                                    value={userInput}
                                                    onChange={handleUserInputChange} />

                                                {/* <button id="submit-button"
                                                    onClick={handleCaptchaSubmit}>
                                                    Submit
                                                </button> */}
                                            </div>
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Send Message" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;