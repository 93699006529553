import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'

const ServiceFeature = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">What We Do</span>
                                        <h2 className="heading-title sc-mb-25">Innovative technology solutions.</h2>
                                        <div className="description sc-mb-25">
                                            Our company specializes in providing innovative technology solutions to businesses of all sizes. We offer a range of services, including self-service kiosks, QR code table ordering, kitchen ticket systems, punch clocks, and multi-region sales analysis. Our goal is to help businesses streamline their operations and improve their customer experience through the use of cutting-edge technology.
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Streamline your operations</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>We ensure automatic updates as needed.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Various restaurant solutions supported</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceFeature;